import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormControl, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { Location } from '@angular/common'
import { interval, Subscription, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { ReportsService } from '../../services/reports.service';
import { PaymentsService } from '../../services/payments.service';
import { OrdersService } from '../../services/orders.service';
import { PrintService } from '../../services/print.service'
import { UsersService } from '../../services/users.service'
import * as FileSaver from 'file-saver';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-daily-transactions',
	templateUrl: './daily-transactions.component.html',
	styleUrls: ['./daily-transactions.component.scss']
})
export class DailyTransactionsComponent implements OnInit {

	@ViewChild('print_table') printtable: ElementRef;

	@ViewChild('voidDetails') voidDetailsRef: ElementRef;

	private sort: MatSort;

	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {

		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	color = 'blue';
	payments: any = [];
	salespeople: any = [];
	today = new Date();
	filename = 'dailytransactions';
	title = 'Daily Transactions';
	@ViewChild('details') detailsRef: ElementRef;
	datefrom = new UntypedFormControl(this.today);
	dateto = new UntypedFormControl(this.today);
	create_credit = new UntypedFormControl(true);
	empcode = new FormControl('');
	running = false;
	hideextrafields = false;
	total_pay: any = '';
	journals: any = false;
	displaydetails: any = false;
	total_trans: any = 0;
	total_sub: any = 0;
	total_tax: any = 0;
	total_freight: any = 0;
	total_fee: any = 0;
	total_discount: any = 0;
	locations: any = [];
	user: any = false;
	defaultlocation = new UntypedFormControl('');
	salesperson = new UntypedFormControl('');
	searcher = new UntypedFormControl('');
	ref_location: any = false;
	exporting = false;
	config: any = false;


	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [25, 50, 100, 200, 500];
	pagesizedefault = 200;
	voiddate = new UntypedFormControl(new Date());
	void_trans: any = false;
	voiding = false;
	isPosUser = false;
	emperror = false;
	searchresults: any = false;

	cctotal = 0;
	payment_methods: any = false;
	fetchingJournals: any = false;

	constructor(private ordersService: OrdersService, private usersService: UsersService, private _changeDetectorRef: ChangeDetectorRef, private location: Location, private reportsService: ReportsService, private printService: PrintService, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private paymentsService: PaymentsService, private fb: UntypedFormBuilder, private route: ActivatedRoute, public router: Router, private modalService: NgbModal) {

		this.globalSearchService.configsubscription.subscribe(s => {
			this.config = s;
			if (this.config.env.package === 'beauty') {
				this.hideextrafields = true;
			}
		})

		this.color = this.globalSearchService.getColor();

		this.globalSearchService.payment_methods.subscribe(r => {
			this.payment_methods = r;
		});

		this.globalSearchService.user.subscribe(results => {
			//only run if user is definied

			if (this.user) {
				if (results.user.defaultlocation.loccode !== this.ref_location) {
					this.defaultlocation.setValue([this.user.user.defaultlocation.loccode]);
					this.ref_location = false;
				}
			}

			this.user = results

			if (!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
			}
		});

		this.usersService.isPosUser().subscribe((isPOS) => {
			if (isPOS) {
				this.isPosUser = true;
			}
			this.globalsService.getLocations().subscribe(async (results: any) => {
				if (results) {
					// Assuming isPosUser is a boolean indicating whether the user is a POS user

					if (this.isPosUser || this.user.user.issalesman) {
						// Filter locations to only include the user's default location
						this.locations = results.filter(location =>
							location.loccode === this.user.user.defaultlocation.loccode
						);

						const val = [
							this.user.user.defaultlocation.loccode
						];
						this.defaultlocation.setValue(val);
					} else {
						// If the user is not a POS user or a salesman, use all locations
						this.locations = results;
					}

					this.loadData();
				}
			});
		})


		this.reportsService.getSalesPeople().subscribe((results: any) => {
			this.salespeople = results;
		});

		this.searcher.valueChanges.subscribe(newValue => {
			this.filterSearch();
		});
	}

	filterSearch() {

		if (this.searcher.value == '') {
			this.searchresults = this.payments.transactions;
			this.setPagination(this.searchresults);
		} else {
			this.searchresults = this.globalSearchService.filterItem(this.payments.transactions, this.searcher.value, 'orderno,order_,transno,debtorno,trandate,name')
			this.setPagination(this.searchresults);
		}
	}


	ngOnInit(): void {
		this.setPagination([]);
	}

	loadData() {
		this.running = true;
		const data = { from: this.datefrom.value, to: this.dateto.value, locations: this.defaultlocation.value, salesperson: this.salesperson.value }
		this.paymentsService.getDailyTransactions(data).subscribe((results: any) => {
			this.running = false;
			this.payments = results;

			if (results.transactions.length) {
				this.items_per_page = [25, 50, 100, 500, results.transactions.length];
			}

			this.setPagination(results.transactions);

			// Utility function to calculate the total
			const calculateTotal = (transactions: any[], key: string) =>
				transactions.reduce((accumulator: number, item: any) => accumulator + parseFloat(item[key]), 0);

			// Assign totals using the utility function
			this.total_trans = calculateTotal(results.transactions, 'total');
			this.total_sub = calculateTotal(results.transactions, 'ovamount');
			this.total_tax = calculateTotal(results.transactions, 'ovgst');
			this.total_freight = calculateTotal(results.transactions, 'ovfreight');
			this.total_fee = calculateTotal(results.transactions, 'ovfee');
			this.total_discount = calculateTotal(results.transactions, 'ovdiscount');

			// Calculate cctotal
			this.cctotal = results.summary.reduce((accumulator: number, item: any) => {
				const ttype = this.payment_methods.find((t: any) => t.paymentname === item.type);
				const value = (ttype && ttype.cc_process === '1') ? parseFloat(item.amount) : 0;
				return accumulator + value;
			}, 0);

			// Calculate total_pay
			this.total_pay = results.summary.reduce((accumulator: number, item: any) => accumulator + parseFloat(item.amount), 0);

			this.getJournals();
		})
	}

	getLocationNamesFormatted(): string {
		if (!this.defaultlocation.value.length) return 'N/A';

		return this.defaultlocation.value.map(v => {
			const loc = this.locations.find(r => v === r.loccode);
			return loc ? loc.locationname : '';
		}).filter(Boolean).join(', ');
	}


	getLocationName() {
		let name = 'N/A';
		if (this.defaultlocation.value.length) {
			name = '';
			this.defaultlocation.value.forEach(v => {

				const loc = this.locations.filter(r => {
					return v === r.loccode
				})[0];
				if (loc) {
					name += loc.locationname + ' ';
				}
			});
		}

		return name;
	}

	getLocationNames(): string[] {
		if (!this.defaultlocation.value.length) return ['N/A'];

		return this.defaultlocation.value.map(v => {
			const loc = this.locations.find(r => v === r.loccode);
			return loc ? loc.locationname : '';
		}).filter(Boolean); // Filter out undefined or empty locations
	}

	getJournals() {
		const data = {};
		if (this.fetchingJournals) {
			this.fetchingJournals.unsubscribe();
		}
		this.fetchingJournals = this.paymentsService.getClosedJournals(data)
			.pipe(take(1)) // This ensures the observable completes after the first emission
			.subscribe((result: any) => {
				this.journals = result;
			});
	}


	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		const locations = this.defaultlocation.value && this.defaultlocation.value.length > 0 ?
			this.defaultlocation.value.join(',') :
			'No locations';

		const data = {
			content: encoded,
			filename: this.filename,
			landscape: true,
			title: 'Daily Transactions',
			subtitle: 'Locations: ' + locations + ' ' + this.datefrom.value.toLocaleDateString() + ' - ' + this.dateto.value.toLocaleDateString() + ' created ' + today.toLocaleString()
		};

		this.exporting = true;
		this.printService.pdf(data).subscribe((result: any) => {
			this.exporting = false;
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {


		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		const locations = this.defaultlocation.value && this.defaultlocation.value.length > 0 ?
			this.defaultlocation.value.join(',') :
			'No locations';

		const data = {
			content: encoded,
			filename: this.filename,
			title: 'Daily Transactions',
			subtitle: 'Locations: ' + locations + ' ' + this.datefrom.value.toLocaleDateString() + ' - ' + this.dateto.value.toLocaleDateString() + ' created ' + today.toLocaleString()
		};

		this.exporting = true;
		this.printService.xls(data).subscribe((result: any) => {
			this.exporting = false;
			this.globalSearchService.downloadXls(result.content, data.filename);
		});

	}

	updateDayPayMents(event: any) {
		this.today = event;
		this.loadData();
	}

	back(): void {
		this.location.back();
	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource < any > (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
	}

	voidPayment(pay: any) {

		this.void_trans = pay;
		this.modalService.open(this.voidDetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => {

		}, (reason) => {
			this.void_trans = false;
		});

	}

	processVoid() {

		let proceed = true;

		if (this.isPosUser) {
			const empinput = { employee: this.empcode.value }
			this.ordersService.getEmployee(empinput).subscribe((empresult: any) => {
				if (empresult.success) {
					this.emperror = false;
					proceed = true;

					if (proceed) {
						const data = {
							transaction: this.void_trans,
							create_credit: this.create_credit.value,
							voiddate: this.voiddate.value,
							employee: this.empcode.value
						}

						this.voiding = true;
						this.paymentsService.voidInvoice(data).subscribe((result: any) => {
							this.voiding = false;
							if (result.success) {
								this.loadData();
								this.globalSearchService.showNotification(result.message, 'success', 'bottom', 'left')
								this.modalService.dismissAll();
							} else {
								alert(result.message)
							}
						})
					}

				} else {
					this.empcode.setValue('');
					this.emperror = true;
					proceed = false;
				}
			});
		} else {
			//confirm('Void $'+this.financial(this.void_trans.amount)+' on XX'+this.void_trans.cc_data.card_last_four+'?');
			if (proceed) {
				const data = {
					transaction: this.void_trans,
					create_credit: this.create_credit.value,
					voiddate: this.voiddate.value
				}

				this.voiding = true;
				this.paymentsService.voidInvoice(data).subscribe((result: any) => {
					this.voiding = false;
					if (result.success) {
						this.loadData();
						this.globalSearchService.showNotification(result.message, 'success', 'bottom', 'left')
						this.modalService.dismissAll();
					} else {
						alert(result.message)
					}
				})
			}
		}
	}



	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	openDocument(transaction: any, display: string) {

		switch (transaction.type) {
			case '11':
				this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((mresult) => {}, (reason) => {});
				})
				break;
			case '10':
				this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((mresult) => {}, (reason) => {});
				})

				break;
		}
	}


}